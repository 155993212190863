<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <!-- <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Users' }"
          >Back</b-button
        > -->
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap items-center flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <b-field
                label="Email"
                :type="$store.getters.hasErrors('email').type"
                :message="$store.getters.hasErrors('email').message"
              >
                <b-input v-model="email"></b-input>
              </b-field>
              <b-field
                label="New Password"
                :type="$store.getters.hasErrors('password').type"
                :message="$store.getters.hasErrors('password').message"
              >
                <b-input v-model="password" type="password"></b-input>
              </b-field>
              <div class="flex items-center justify-end">
                <b-button
                  type="is-success"
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  expanded
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    name: "",
    email: "",
    password: ""
  }),
  computed: {},
  methods: {
    onSave() {
      store.dispatch("saveAccountForm", {
        id: store.getters.activeUser.id,
        name: this.name,
        email: this.email,
        password: this.password || undefined
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(res => {
        next(vm => {
          vm.name = res.data.name;
          vm.email = res.data.email;
        });
      });
    }
  }
};
</script>
